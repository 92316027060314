import React from 'react'
import Layout from '../components/layout'
import 'react-multi-carousel/lib/styles.css'

// Components
import { Banner, BlogList, Input, Map, Suggestion } from '../components'
import MailIcon from '@material-ui/icons/Mail'
import SubscriptionImage from '../images/subscription.jpg'

const IndexPage = props => {

  const d = new Date()
  const d2 = new Date(2021, 10, 5, 0, 0, 1)
  console.log(d > d2)

  return(
    <Layout
      title = { 'Siu & Sons: Party & Balloons Wholesale (website: B2B only)' }
      index
      description = { `Siu & sons international trading corporation in Vancouver Canada` }
    >
    <Banner />
    <div className='container' >
      <div style = {{ minHeight: '60vh'}}>
        <Suggestion 
          title = { 'New arrival' } 
          data = { 
            props.pageContext.product.slice( 0, props.pageContext.productPerRow - 1 ).map( product => { 
              return { 
                title : product.node.title, 
                image : product.node.images.edges.length? product.node.images.edges[0].node.originalSrc : null,
                handle : product.node.handle
              }  
            })
          }
        />
      </div>
      <div style = {{ minHeight: '60vh'}}>
        <Suggestion 
          data = { 
            props.pageContext.product.slice( props.pageContext.productPerRow, 2*props.pageContext.productPerRow-1 ).map( product => { 

              return { 
                title : product.node.title, 
                image : product.node.images.edges.length? product.node.images.edges[0].node.originalSrc : null,
                handle : product.node.handle
              }
              
            })
          }
        />
      </div>
      <div style = {{ minHeight: '60vh'}}>
        <Suggestion 
          data = { 
            props.pageContext.product.slice( 2*props.pageContext.productPerRow, 3*props.pageContext.productPerRow -1 ).map( product => { 

              return { 
                title : product.node.title, 
                image : product.node.images.edges.length? product.node.images.edges[0].node.originalSrc : null,
                handle : product.node.handle
              }
              
            })
          }
        />
      </div>
      <div style = {{ minHeight: '50vh'}}>
        <h3>Blog</h3>
        <BlogList/>
      </div>
      <div style = {{ minHeight: '20vh'}}/>
      <h2 style = {{ color : 'rgba( 0, 0, 0, 0.9 )' }}>Subscribe for the latest promotions</h2>
      <div
        style = {{
          backgroundImage : `url( ${ SubscriptionImage } )`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          boxShadow: '0 0 10px grey'
        }}
      >
          <div 
            style = {{
              width: '100%',
              height: '40vh',
              minHeight: '200px',
              display: 'flex',
              flexDirection: 'column',
              alignItems : 'center',
              justifyContent : 'space-around',
              backgroundColor : 'rgba( 255, 255, 255, 0.5 )',
              backdropFilter : 'blur(0px)'
            }}
          >
            <div style = {{
              height : '6vh',
              width : '50%',
              display: 'flex',
            }}>
              <Input
                icon = { <MailIcon/> }
                placeholder = { 'Email' }
                reverse
              />
            </div>
          </div>
        </div>
        <div style = {{ minHeight: '20vh'}}>
        
        </div>
        <h3>Visit our showroom</h3>
        <Map/>
        <div style = {{ minHeight: '20vh'}}/>
    </div>
    </Layout>
  )
}

export default IndexPage
